import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import { PageHeading, Heading } from 'src/components/molecules/Heading'
import { Inner } from 'src/components/layouts/Inner'
import Layout from 'src/components/Layout'
import { ComponentStyleProps } from 'src/types'
import { Philosophy } from 'src/components/domains/company/Philosophy'
import { CompanyOverview, CompanyOfficer } from 'src/components/domains/company/CompanyInfo'
import { ElementContainer } from 'src/components/layouts/ElementContainer'
import { useSiteMetadata } from 'src/hooks'
import PageMeta from 'src/components/PageMeta'

type Props = ComponentStyleProps

export const CompanyPageTemplate: React.FC<Props> = () => {
  return (
    <>
      <ScPageHeading component="h2" heading="会社概要" subHeading="Overview" />

      <ElementContainer>
        <CompanyOverview />
      </ElementContainer>

      <ScHeading>経営理念</ScHeading>
      <ElementContainer>
        <Philosophy />
      </ElementContainer>

      <ScHeading>役員一覧</ScHeading>
      <CompanyOfficer />
    </>
  )
}

// CSS in JS
const ScPageHeading = styled(PageHeading)`
  margin-bottom: 2.5rem;
  ${mediaQuery('sm')} {
    margin-bottom: 5rem;
  }
`

const ScHeading = styled(Heading)`
  margin-bottom: 1.5rem;
  ${mediaQuery('sm')} {
    margin-bottom: 2.5rem;
  }
`
// End CSS in JS

const CompanyPage: React.FC = () => {
  const { siteTitle } = useSiteMetadata()

  return (
    <Layout>
      <PageMeta title={`会社概要 | ${siteTitle}`} />
      <Inner>
        <CompanyPageTemplate />
      </Inner>
    </Layout>
  )
}

export default CompanyPage
