import React from 'react'
import styled, { mediaQuery } from 'src/styles'

export const Philosophy: React.FC = () => {
  return (
    <ScWrapper>
      <ScItem>
        <ScTitle>
          <p>Slogan</p>
          <span>スローガン</span>
        </ScTitle>
        <ScList>
          <li>新しいオモシロイを実現する</li>
          <li>面白くなければ仕事じゃない！</li>
          <li>新しくなければ創造じゃない！</li>
        </ScList>
      </ScItem>

      <ScItem>
        <ScTitle>
          <p>Promise</p>
          <span>約束</span>
        </ScTitle>
        <ScList>
          <li>今は無き新しい面白いサービスの創造に全力を尽くします。</li>
          <li>サービスに対しての妥協は一切致しません。</li>
          <li>いつ如何なる時もユーザー視点を第一に考えて行動します。</li>
          <li>個の能力を最大限に活かした組織を目指します。</li>
        </ScList>
      </ScItem>

      <ScItem>
        <ScTitle>
          <p>Source</p>
          <span>社名由来</span>
        </ScTitle>
        <ScList>
          <li>
            <b>~Creaitorʼs Ace in the Hole~ Play Ground Company</b>
          </li>
          <li>
            <b>創造社会の最後の切り札</b>
          </li>
          <li>株式会社Creaithは上記言葉の略称です。</li>
          <li>次世代に繋ぐ創造集団として、自身を鼓舞する想いでつけた名前です。</li>
          <li>
            ITビジネスの急激な成長の中で、求められる独創性を追求しクライアントニーズをキャッチした最強のクリエイティブ集団且つ、最強のマーケッター集団で形成される組織こそ私共Creaithの求める集合体です。
          </li>
        </ScList>
      </ScItem>
    </ScWrapper>
  )
}

const ScWrapper = styled.div`
  background-color: #f7f7f7;
  padding: 1rem;
  ${mediaQuery('sm')} {
    padding: 2.5rem 5rem;
  }
`

const ScItem = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid #000;
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;
  }
  ${mediaQuery('sm')} {
    display: flex;
  }
`

const ScTitle = styled.div`
  margin-bottom: 1rem;
  p {
    color: #a5a5a5;
    font-size: 2rem;
    font-family: ${({ theme }) => theme.fontFamily.headingEn};
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0.25em;
  }
  span {
    display: block;
    font-size: 1rem;
    font-weight: bold;
  }
  ${mediaQuery('sm')} {
    margin-bottom: 0;
    width: 400px;
    p {
      font-size: 4rem;
    }
    span {
      font-size: 1.5rem;
    }
  }
`

const ScList = styled.ul`
  font-size: 0.75rem;
  li {
    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }
  ${mediaQuery('sm')} {
    flex: 1;
    font-size: 1rem;
  }
`
