import React from 'react'
import styled, { mediaQuery } from 'src/styles'

export const CompanyOverview: React.FC = () => {
  return (
    <ScWrapper>
      <ScTable>
        <tbody>
          <tr>
            <th>社名</th>
            <td>株式会社Creaith</td>
          </tr>
          <tr>
            <th>事業内容</th>
            <td>
              Webマーケティング事業
              <br />
              システムインテグレーション事業
              <br />
              ITプロダクト事業
              <br />
              コンサルティング事業
            </td>
          </tr>
          <tr>
            <th>設立</th>
            <td>2015年7月31日</td>
          </tr>
          <tr>
            <th>資本金</th>
            <td>5,000,000円(2018年2月現在)</td>
          </tr>
          <tr>
            <th>代表者</th>
            <td>深見 佳雄</td>
          </tr>
        </tbody>
      </ScTable>

      <ScTable>
        <tbody>
          <tr>
            <th>本社</th>
            <td>
              〒252-0243
              <br />
              神奈川県相模原市中央区上溝2455-16
            </td>
          </tr>
        </tbody>
      </ScTable>
    </ScWrapper>
  )
}

const ScWrapper = styled.div`
  ${mediaQuery('sm')} {
    display: flex;
    justify-content: space-between;
  }
`

const ScTable = styled.table`
  width: 100%;
  font-size: 0.875rem;
  th {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    text-align: right !important;
    padding: 1.5rem 1rem;
    width: 120px;
  }
  td {
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
    padding: 1.5rem 1rem;
  }
  &:not(:last-of-type) {
    margin-bottom: -1px;
  }
  ${mediaQuery('sm')} {
    font-size: 1rem;
    width: 48%;
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
    th {
      width: 200px;
    }
  }
`

// Officer

export const CompanyOfficer: React.FC = () => {
  return (
    <ScTable>
      <tbody>
        <tr>
          <th>会長 兼 CEO</th>
          <td>深見 佳雄</td>
        </tr>
        <tr>
          <th>代表取締役 兼 CFO</th>
          <td>藤代 健之</td>
        </tr>
        <tr>
          <th>取締役 兼 CTO</th>
          <td>志村 直人</td>
        </tr>
      </tbody>
    </ScTable>
  )
}
